import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {
  connect() {
  }

  updateNavbar() {
    if (window.scrollY >= (window.innerHeight)*70/100) {
      this.element.classList.add("navbar-ns-home")
    } else {
      this.element.classList.remove("navbar-ns-home")
    }
  }
}
