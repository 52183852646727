import { Controller } from "@hotwired/stimulus"
import Typed from "typed.js"

export default class extends Controller {
  connect() {
    new Typed(this.element, {
      strings: ["de l'océan ^1000 ", "des mers", "de la voile","du surf","de la navigation", "de la glisse"],
      showCursor: false,
      loop: true,
      typeSpeed: 30,
      startDelay: 0,
      backDelay: 500,
      backSpeed: 30,
      smartBackspace: true,
    })
  }
}
