import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    new flatpickr(this.element, {
      allowInput: true,
      altFormat: "F j, Y",
      dateFormat: "d M Y",
      minDate: "today",
      // inline: true,
      // wrap: true,
    });

    new flatpickr(".datepicker_from1900", {
      allowInput: true,
      altFormat: "F j, Y",
      dateFormat: "d M Y",
      minDate: "01/01/1990",
    });

    new flatpickr(".datepicker_createlesson", {
      altFormat: "F j, Y",
      allowInput: true,
      dateFormat: "d M Y H:i",
      minDate: "today",
      enableTime: true,
      time_24hr: true,
      minTime: "00:00",
    });

    new flatpickr(".timepicker", {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      minTime: "09:00"
    });

    new flatpickr(".datepicker_demo", {
      altFormat: "F j, Y",
      allowInput: true,
      dateFormat: "d M Y H:i",
      minDate: "today",
      enableTime: true,
      time_24hr: true,
      minTime: "00:00",
    });
  }
}
